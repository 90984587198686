<template>
	<v-sheet color="transparent" class="pa-4">
		<v-text-field v-model="search" :label="mwtr.Search"></v-text-field>
		<v-card flat color="secondary">
			<v-data-table :headers="headers" :items="items" :search="search">
				<template v-slot:item.button="{ item }">
					<ListButtons collection="users" :id="item.id"></ListButtons>
				</template>
			</v-data-table>			
		</v-card>
		<v-row>
			<v-col>
				<v-spacer></v-spacer>
				<v-btn color="primary" to="/admin/users/new">{{mwtr.NewUserNavItem}}</v-btn>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ListButtons from "@/components/admin/lists/ListButtons"

	export default {
		name: "Users", 
		props: {
			
		},
		data: () => {
			return {
				search: ""
			}
		},
		components: {
			ListButtons
		},
		computed: {
			...Vuex.mapState({
				users: state => state.users
			}), 
			headers(){
				return [
					{value: "email", text: "Email"},
					{value: "button", text: " "},
				]
			}, 
			items(){
				return Object.values(this.users)
			}
		},
		// methods: {
		// }
		created(){
			this.$store.dispatch("users/fetchAll")
      }

	}
// </script>"